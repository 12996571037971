import React, { useState, useEffect, useCallback } from "react";
import styles from './vp_fpl.module.scss';
import {Table,Container,Row,Col,Button,Toast} from "react-bootstrap"
import GoogleAd from '../components/GoogleAd'

const axios = require("axios");

const url = 'https://o8bbxwfg8k.execute-api.eu-west-1.amazonaws.com/dev/api/videprinter';

function createMarkup(props) {
  const textx = props.details;
  console.log(textx);
  return {__html: textx};
}

function createMarkup0(texta) {
  const textab = texta
  const textx = "helllllo";
  // console.log(textab);
  return {__html: textab};
}

function VpBody(props) {
  const texta = props.details;
  // console.log(texta);
  return <div dangerouslySetInnerHTML={createMarkup0(texta)} />;
}


const useMatchesState = () => {

  const [responseData, setResponseData] = useState([]);
  var details = "";
  var tme = "";

  if (Object.keys(responseData).length > 0) {
    details = responseData.details;
    tme = responseData.tme;
  }

  const fetchFpl = async (url) => {
    console.log('fetch_url:'+url)
    const response = await axios.get(url);
    setResponseData(response.data);
    console.log("fetchran");
  };

  useEffect(() => {
    fetchFpl(url);
    // setGW(responseData.data.status.gw);
    console.log("useeffect ran");
    const intervalId = setInterval(() => {  //assign interval to a variaable to clear it
      fetchFpl(url);
      console.log("useeffect ran again");
    }, 60000)

    return () => {
      clearInterval(intervalId); //This is important
      console.log("clearInterval");
    }

  }, [url])
  // },)

// return {gwPage, gwTime, setUrl, newUrl, fixtures,show,handleShow,handleClose,playerData};
return {tme,details};
}

const Videp = () => {

  const {
    tme,
    details,
  } = useMatchesState();

return (
<>
  <Container fluid className={styles.vbody}>
    <Row>
      <Col xs={{span:12, order:2}} lg={{span:2, order:1}}>
      <GoogleAd/>
      </Col>
      <Col xs={{span:12, order:1}} lg={{span:8, order:2}}>
        <p className={styles.tmep1}>337</p>
        <p className={styles.tmep2}><a href="https://twitter.com/JoeRyan365">@JoeRyan365</a> &nbsp;&nbsp;&nbsp;<a href="https://twitter.com/fpl_alerts">@fpl_alerts</a></p>
        <p className={styles.tmep3}><span className={styles.tme}></span></p>
        <h1><span className={styles.fpha}>F</span><span className={styles.fphs}>&nbsp;</span><span className={styles.fpha}>P</span><span className={styles.fphs}>&nbsp;</span><span className={styles.fpha}>L</span><span className={styles.fphs}>&nbsp;</span><span className={styles.fph}>&nbsp;FOOTBALL&nbsp;</span></h1>
        <p className={styles.gr}>ROLLING VIDEPRINTER</p>
        <br></br>
        <VpBody details={details}/>
      </Col>
      <Col xs={{span:12, order:3}} lg={{span:2, order:3}}>
      <GoogleAd/>
      </Col>
    </Row>
  </Container>
</>
);
}

export default Videp

// <Container fluid className ={styles.fpha}>
//   <Row>
//     <Col>
//       <h5>
//         Page automatically refreshes
//       </h5>
//       <p>
//         nwejvnowvn j0iecnow woec nowc ow cnewocnwocvnweocvnoewcnoewc  noiwen onewocno newoic o noiqwcnoi fweoic now cniowc wo ncowe cnoiw noiwec nowec nwof niocwe cnowc
//       </p>
//     </Col>
//   </Row>
// </Container>
