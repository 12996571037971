import React from 'react'
// import Layout from '../components/layout'
import Header from '../components/Header.js'
import Footer from '../components/Footer.js'
import Vprinter from '../components/V-printer.js'
import HeadTags from '../components/Headtags'

const Videprinter = () => (
  <>
    <HeadTags />
    <div style ={{position:"relative", minHeight:"100vh"}}>
      <Header />
      <Vprinter />
      <Footer />
    </div>
  </>
)

export default Videprinter
